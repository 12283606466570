import React from 'react';
import Icon from '@ant-design/icons';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Menu, Button} from "antd";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faListAlt, faTachometerAlt, faHistory, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
import Icon_07 from '../../assets/zh_icon/icon_07.png'
import Icon_09 from '../../assets/zh_icon/icon_09.png'
import Icon_10 from '../../assets/zh_icon/icon_10.png'
import Icon_11 from '../../assets/zh_icon/icon_11.png'
import Icon_12 from '../../assets/zh_icon/icon_12.png'
import RoomB from '../../assets/menu_icon/会议室看板.png'
import WatchB from '../../assets/menu_icon/会议查看.png'
import RecordB from '../../assets/menu_icon/会议记录.png'
import BookB from '../../assets/menu_icon/会议预约.png'
import ContactB from '../../assets/menu_icon/contact-w.png'
// import PortalB from '../../assets/menu_icon/首页-黑.svg'
// import Icon_07 from '../../assets/menu_icon/会议室看板-白.svg'
import { EventEmitter } from "events";
export const menuEmitter =  new EventEmitter();


class PortalMenu extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            collapsed : false,
            menus: [],
            // defaultSelectedKeys : ['0']
        }
    
        menuEmitter.addListener('setSeleted', (data) => {
            // console.warn('setSeleted', data);
            this.setState({
                defaultSelectedKeys : [data]
            })
        })
    }

    componentDidMount(){
        let allMenus = this.props.portal.myData.menus;
        let portalMenus = []
        allMenus.map(menu => {
            if (menu.serviceType == 1 && menu.isShow == 1) {
                portalMenus.push(menu)
            }
        })
        portalMenus.sort((a, b) => a.priority < b.priority)
        
        this.setState({
            menus: portalMenus
          });

        // console.log("菜单===》"+JSON.stringify(portalMenus))
    }

    render() {

        const { menus } = this.state;
        console.log('----menus: ', menus);
        return (
            <Menu
                id='portal-menu'
                style={{width : this.state.collapsed ? 50 : 220, position : 'relative', 'flex-shrink': 0}}
                selectedKeys={this.state.defaultSelectedKeys}
                // defaultOpenKeys={['infoManagement']}
                theme="dark"
                mode='inline'
                inlineCollapsed={this.state.collapsed}

                onClick={(menuItemInfo) => {
                    console.log('-----------menuItemInfo', menuItemInfo.key);
                    if(menuItemInfo.key === 'other') return;
                    this.setState({
                        defaultSelectedKeys : [menuItemInfo.key]
                    })
                }}
            >
                
                {/* TODO 权限判断没加 估计是只有管理员有 */}
                <Menu.Item key='dashboard' icon={<img style={{height : 20,  marginRight : 4}} alt='' src={require(`../../assets/menu_icon/日历.png`)}></img>}>
                    <Link to={'/dashboard'}>会议工作台</Link>
                </Menu.Item>

                {menus.map(item => (
                    <Menu.Item key={item.code} icon={<img style={{height : 20,  marginRight : 4}} alt='' src={require(`../../assets/menu_icon/${item.clazz}`)}></img>}>
                        <Link to={item.dataUrl}>{item.name}</Link>
                    </Menu.Item>
                ))}
                <Menu.Item key='map' icon={<img style={{height : 20,  marginRight : 4}} alt='' src={require(`../../assets/zh_icon/map_2.png`)}></img>}>
                    <Link to={'/map'}>会议室地图</Link>
                </Menu.Item>
                {/* <Menu.Item key='map' icon={<img style={{height : 20,  marginRight : 4}} alt='' src={require(`../../assets/zh_icon/count_01.png`)}></img>}>
                    <Link to={'/workbench'}>会议统计</Link>
                </Menu.Item> */}

                <div key='4' style={{position : 'absolute', bottom : 20, width : '100%', display : 'flex', alignItems : 'flex-end', justifyContent : 'center'}}>
                <Button type="link" onClick={() => {
                    const picker = document.querySelector('.ant-picker-dropdown-placement-bottomLeft')
                    if (picker) {
                        picker.style.left = this.state.collapsed ? '267px' : '102px'
                    }
                    this.setState({
                    collapsed: !this.state.collapsed,
                    });
                }} style={{ color: 'white' }}>
                    {this.state.collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                </Button>
                </div>
            </Menu>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        portal : state.portal,
    }
};

const mapDispachToProps = (dispatch) => {
    return {
        setPortalReducer : (target) => {
            dispatch ({
                type : "setPortalReducer",
                payload : target
            });
        },

        resetBookingReducer : (target) => {
            dispatch ({
                type : "resetBookingReducer",
                payload : target
            });
        },
    }
};

export default connect(mapStateToProps, mapDispachToProps)(withRouter(PortalMenu));


